<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="75%"
      :before-close="handleClose"
    >
      <div class="img-dialog">
        <el-form
          label-position="right"
          label-width="100px"
          :inline="true"
          :rules="rules"
          :model="formData"
          ref="dataForm"
        >
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="商品描述" prop="content">
            <el-input v-model="formData.content"></el-input>
          </el-form-item>
          <el-form-item label="公示价格" prop="price">
            <el-input v-model="formData.price"></el-input>
          </el-form-item>
          <el-form-item label="折后价格" prop="discountPrice">
            <el-input v-model="formData.discountPrice"></el-input>
          </el-form-item>
          <!-- </el-form>
        <el-form
          label-position="right"
          label-width="85px"
          :inline="true"
          :rules="rules"
          :model="formData"
        > -->
          <el-form-item label="商品类型" prop="productType">
            <el-select v-model="formData.productType" placeholder="请选择">
              <el-option label="权益包" value="01"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权益包名称" prop="relateId">
            <el-select v-model="formData.relateId" placeholder="请选择">
              <el-option
                v-for="item in packageList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品状态" prop="status">
            <el-select v-model="formData.status" placeholder="请选择">
              <el-option label="正常" :value="1"> </el-option>
              <el-option label="停用" :value="0"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示状态" prop="showScene">
            <el-select v-model="formData.showScene" placeholder="请选择">
              <el-option label="显示" value="1"> </el-option>
              <el-option label="不显示" value="0"> </el-option>
            </el-select>
          </el-form-item>
          <!-- </el-form>
        <el-form label-position="right" label-width="85px" :inline="true"> -->
          <el-form-item label="分享标题">
            <el-input v-model="formData.shareTitle"></el-input>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input v-model="formData.shareDescription"></el-input>
          </el-form-item>

          <el-form-item label="是否立即激活" prop="activeHealthCardFlag">
            <el-select
              v-model="formData.activeHealthCardFlag"
              placeholder="请选择"
            >
              <el-option label="是" :value="1"> </el-option>
              <el-option label="否" :value="0"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="img-list">
          <div class="img-item">
            <p>点击上传商品主图</p>
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="bannerSuccess"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
            >
              <img
                v-if="formData.productPicture"
                :src="formData.productPicture"
                class="avatar"
              />

              <div v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </div>
          <div class="img-item">
            <p>点击上传商品列表图</p>
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="listSuccess"
            >
              <img
                v-if="formData.productListPicture"
                :src="formData.productListPicture"
                class="avatar"
              />

              <div v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </div>
          <div class="img-item">
            <p>点击上传商品分享图</p>
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="shareSuccess"
            >
              <img
                v-if="formData.sharePicture"
                :src="formData.sharePicture"
                class="avatar"
              />

              <div v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </div>
        </div>

        <div class="detail-section">
          <p>商品详情</p>
          <quill-editor
            ref="myTextEditor"
            v-model="formData.productDetails"
            :options="editorOption"
            style="height: 300px"
            @change="onEditorChange($event)"
          ></quill-editor>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handelSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import cloneDeep from 'lodash/cloneDeep';
import editorOption from './editorOption';
const URL_OBJ = {
  package: '/health/package/list',
  save: '/demo/product/save',
  update: '/demo/product/update',
};

export default {
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: '请输入商品描述',
            trigger: 'blur',
          },
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        discountPrice: [
          {
            required: true,
            message: '请输入折后价格',
            trigger: 'blur',
          },
        ],
        content: [{ required: true, message: '请摄入价格', trigger: 'blur' }],
        productType: [
          {
            required: true,
            message: '请选择商品类型',
            trigger: 'change',
          },
        ],
        relateId: [
          {
            required: true,
            message: '请选择权益包',
            trigger: 'change',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择商品状态',
            trigger: 'change',
          },
        ],
        showScene: [
          {
            required: true,
            message: '请选择权显示状态',
            trigger: 'change',
          },
        ],
        //
      },
      action: `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
      dialogVisible: false,
      formData: {
        id: '', //商品id
        name: '', //商品名称
        content: '', //商品描述
        price: '', //公示价格
        discountPrice: '', //折后价格
        productType: '', //商品类型
        relateId: '', //权益包名称
        status: '', //商品状态
        showScene: '', //显示状态
        shareTitle: '', //分享标题
        shareDescription: '', //分享描述
        productDetails: '', //商品详情
        productPicture: '',
        productListPicture: '', //https://duihui.duoduocdn.com/zuqiu/bierbae.png
        sharePicture: '',
        activeHealthCardFlag: 0, //是否立即激活 0表示否 1表示是
      },
      editorOption,
      packageList: [],
    };
  },
  props: {
    itemData: {
      type: Object,
      require: false,
    },
  },
  async mounted() {
    const res = await this.$http.get(URL_OBJ.package);

    const resData = res.data.data || [];
    this.packageList = resData.map((item) => {
      return {
        label: item.packageName,
        value: item.id,
      };
    });
  },
  watch: {
    watchItem: {
      immediate: true,
      deep: true,
      handler(newVal) {
        let deepObj = {};
        if (Object.keys(newVal).length) {
          deepObj = cloneDeep(newVal);
        }
        this.formData = deepObj;
      },
    },
  },
  components: {
    quillEditor,
  },
  methods: {
    handelSure() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (!valid) return;

        //编辑
        if (this.watchItem.id) {
          //
          const res = await this.$http.post(URL_OBJ.update, this.formData);
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.$emit('updatTable');
        } else {
          //新增

          const res = await this.$http.post(URL_OBJ.save, this.formData);
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.$emit('updatTable');
        }
        this.dialogVisible = false;
      });
    },
    handleClose() {
      this.$confirm('确认关闭？')
        .then(async (_) => {
          // done();
          this.dialogVisible = false;
          this.$refs['formData'].resetFields();
          // Object.assign(this.$data, this.$options.data());
        })
        .catch((_) => {});
    },
    bannerSuccess(res) {
      this.$set(this.formData, 'productPicture', this.initImg(res));

      // this.formData.productPicture = URL.createObjectURL(file.raw);
    },
    listSuccess(res) {
      this.$set(this.formData, 'productListPicture', this.initImg(res));
    },
    shareSuccess(res) {
      this.$set(this.formData, 'sharePicture', this.initImg(res));
    },

    initImg(res) {
      const { code = 99, data = {}, msg = '网络异常' } = res;
      if (code != 0) {
        this.$message.error(msg);
        return false;
      }
      return data.src;
    },
    onEditorChange({ _, html, text }) {
      console.log(html, text);
      this.formData.productDetails = html;
    },
  },
  computed: {
    watchItem() {
      return this.itemData;
    },
  },
};
</script>

<style lang="scss">
.img-dialog {
  height: 500px;
  overflow-y: auto;
}
.img-list {
  display: flex;
  padding-left: 20px;
  .img-item {
    margin-right: 50px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.detail-section {
  display: flex;
  margin-top: 25px;
  & > p {
    padding: 0 20px;
  }
}
</style>
